import React, { Fragment } from 'react';
import './index.scss';
import LazyImage from '../LazyImage';

const Footer = () => (
  <Fragment>
    <section className="Section Section-socials">
      <div className="container">
        <div className="Section-socials__apps">
          <a
            className="Section-socials__apps__item"
            href="https://collegehelp.onelink.me/GXVs/footer"
            rel="nofollow"
            aria-label="App Store"
          >
            <LazyImage src="/images/ico_app-store.svg" alt="" />
          </a>
        </div>
        <div className="Section-socials__links">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            className="Section-socials__links__item"
            href="//www.instagram.com/customwritings_hub/"
            target="_blank"
            rel="nofollow noopener"
          >
            <LazyImage src="/images/ico_instagram.svg" alt="" />
          </a>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            className="Section-socials__links__item"
            href="//www.facebook.com/customwritingscom/"
            rel="nofollow noopener"
            target="_blank"
          >
            <LazyImage src="/images/ico_facebook.svg" alt="" />
          </a>
        </div>
      </div>
    </section>
    <footer className="Section main-footer">
      <div className="container">
        <div className="main-footer__row main-footer__row--column-4">
          <div className="main-footer__cell">
            <div className="main-footer__label">Company</div>
            <ul>
              <li>
                <a href="https://www.customwritings.com/about-us.html" rel="nofollow">
                  About Us
                </a>
              </li>
              <li>
                <a href="https://www.customwritings.com/contact-us.html" rel="nofollow">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="https://www.customwritings.com/our-services.html" rel="nofollow">
                  Our Services
                </a>
              </li>
              <li>
                <a href="https://www.customwritings.com/progressive-delivery.html" rel="nofollow">
                  Progressive Delivery
                </a>
              </li>
              <li>
                <a href="https://www.customwritings.com/discount.html" rel="nofollow">
                  Discount
                </a>
              </li>
              <li>
                <a href="https://jobs.customwritings.com/" rel="nofollow">
                  Become a writer
                </a>
              </li>
            </ul>
          </div>
          <div className="main-footer__cell">
            <div className="main-footer__label">Resources</div>
            <ul>
              <li>
                <a href="https://www.customwritings.com/our-essay-samples.html" rel="nofollow">
                  Our Essay Samples
                </a>
              </li>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <li>
                <a
                  href="https://www.customwritings.com/howtowrite/free-essay-tools/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Free Essay Writing Tools
                </a>
              </li>
              <li>
                <a href="https://www.customwritings.com/essay-writing-app.html" rel="nofollow">
                  Essay Writing App
                </a>
              </li>
              <li>
                <a href="https://www.customwritings.com/blog/">Blog</a>
              </li>
              <li>
                <a
                  href="https://www.customwritings.com/frequently-asked-questions.html"
                  rel="nofollow"
                >
                  FAQ
                </a>
              </li>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <li>
                <a
                  href="https://www.customwritings.com/howtowrite/"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Writing Guide
                </a>
              </li>
            </ul>
          </div>
          <div className="main-footer__cell">
            <div className="main-footer__label">Terms</div>
            <ul>
              <li>
                <a href="https://www.customwritings.com/terms-conditions.html" rel="nofollow">
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a href="https://www.customwritings.com/cookie-policy.html" rel="nofollow">
                  Cookie Policy
                </a>
              </li>
              <li>
                <a href="https://www.customwritings.com/privacy-policy.html" rel="nofollow">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://www.customwritings.com/social-responsibility-notice.html"
                  rel="nofollow"
                >
                  Notice on Social Responsibility
                </a>
              </li>
            </ul>
          </div>
          <div className="main-footer__cell">
            <div className="main-footer__langs">
              <div className="main-footer__label">Language</div>
              <div className="main-footer__langs__list">
                <a href="https://cn.customwritings.com/">
                  <LazyImage
                    src="/images/ico_lang-cn.svg"
                    alt="Cn.CustomWritings.com"
                    width="35"
                    height="24"
                  />
                </a>
                <a href="https://customwritings.com/ae">
                  <picture>
                    <source srcSet="/images/ico_lang-ar.webp" type="image/webp" />
                    <img
                      src="/images/ico_lang-ar.png"
                      alt="CustomWritings.com/ae"
                      width="35"
                      height="24"
                    />
                  </picture>
                </a>
                <a href="https://www.customwritings.com/">
                  <LazyImage
                    src="/images/ico_lang-usa.svg"
                    alt="CustomWritings.com"
                    width="36"
                    height="24"
                  />
                </a>
              </div>
            </div>
            <div className="main-footer__accept">
              <div className="main-footer__label">We accept</div>
              <LazyImage src="/images/ico_payments.svg" alt="Payments" />
            </div>
          </div>
        </div>

        <div className="main-footer__row">
          <div className="main-footer__label">Services</div>
          <ul className="main-footer__row main-footer__row--column-4 main-footer__row--services">
            <li>
              <a href="/pay-for-essay-writing.html">Pay for essay</a>
            </li>
            <li>
              <a href="/buy-an-essay-online.html">Buy an essay</a>
            </li>
          </ul>
        </div>
        <div className="main-footer__row main-footer__row--column-1">
          <div className="main-footer__cell main-footer__cell--disclaimer">
            <div className="main-footer__label">
              <span className="hidden-info" data-title="Disclaimer" />
            </div>
            <p>
              <span
                className="hidden-info"
                data-title="Writera Limited: a professional writing service that provides original papers. Our products include academic papers of varying complexity and other personalized services, along with research materials for assistance purposes only. All the materials from our website should be used with proper references."
              />
            </p>
          </div>
        </div>
        <div className="main-footer__copyright">
          <p>&copy; 2008 — {new Date().getFullYear()} Writera Limited. All Rights Reserved.</p>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            className="main-footer__dmca"
            href="https://www.dmca.com/Protection/Status.aspx?ID=35fde21e-6aab-49cc-bed4-2d85382a0b27"
            target="_blank"
            rel="nofollow noopener"
          >
            <img
              src="//images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=35fde21e-6aab-49cc-bed4-2d85382a0b27"
              alt="DMCA.com"
            />
          </a>
        </div>
      </div>
    </footer>
  </Fragment>
);

export default Footer;
